<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<h5>
							<strong>Reportes - Ventas</strong>
						</h5>
					</div>
					<div class="col-md-6 text-right">
						<a-button class="btn btn-success ml5" icon="reload" @click="initModule" />
					</div>
					<div class="col-md-12">
						<div class="col-md-12">
							<hr class="hrText" data-content="♦" />
						</div>
					</div>
				</div>
				<salesReport ref="salesReport" />
			</div>
		</div>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import salesReport from '@/components/reports/sales'

export default {
	name: 'reportsSalesView',
	components: {
		salesReport,
	},
	computed: {
		...mapGetters('reports', ['spinnerLoader', 'spinnerLoaderLabel']),
	},
	mounted() {
		this.initModule()
	},
	methods: {
		initModule() {
			this.$refs.salesReport.getReport()
		},
	},
}
</script>