<template>
	<div class="row">
		<div class="col-md-4">
			<b>Rango de Fecha </b>
			<a-icon type="reload" @click="onResetFilter('defaultDate')" :style="{ fontSize: '14px', color: 'red' }" />
			<a-range-picker style="width: 100%" @change="getReport" v-model="filters.defaultDate" :default-value="filters.defaultDate" :format="dateFormat" />
		</div>
		<div class="col-md-4">
			<download-csv :data="Object.values(dataReport.boutique)"> Descargar reporte CSV boutique </download-csv>
			<download-csv :data="Object.values(dataReport.service)"> Descargar reporte CSV servicio </download-csv>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import downloadCsv from 'vue-json-csv'
const dateFormat = 'YYYY-MM-DD'

export default {
	name: 'reportsSalesComponent',
	components: {
		downloadCsv,
	},
	computed: {
		...mapGetters('reports', ['filters', 'dataReport']),
	},
	data() {
		return {
			dateFormat,
		}
	},
	mounted() {
		this.getReport()
	},
	methods: {
		getReport() {
			let payload = {
				startDate: moment(this.filters.defaultDate[0]).format(dateFormat),
				endDate: moment(this.filters.defaultDate[1]).format(dateFormat),
			}
			this.$store.dispatch('reports/SALES', payload)
		},
		onResetFilter(key) {
			this.$store.dispatch('reports/RESET_ONE_FILTER', key)
		},
	},
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>